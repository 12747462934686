import { TOP_LEVEL_TEAMS } from '@uptime/shared/constants/users';

import * as actionTypes from '../../constants/actionTypes';
import { ADMINISTRATOR_ACCESS_TEAM } from '../../constants/users';

const initialState = {
  teams: TOP_LEVEL_TEAMS,
  search: '',
  sortBy: { column: 'username', order: 'asc' },
  filters: {
    accountId: undefined,
    type: undefined,
    plan: undefined,
    accessTeam: ADMINISTRATOR_ACCESS_TEAM,
    status: undefined,
  },
  pagination: {
    page: 1,
    itemsAmount: 10,
  },
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_TEAMS:
      return { ...state, teams: action.payload.teams, pagination: initialState.pagination };
    case actionTypes.SET_USER_SEARCH:
      return { ...state, search: action.payload.search, pagination: initialState.pagination };
    case actionTypes.SET_USER_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_USER_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case actionTypes.SET_USER_ACCOUNT_ID_FILTER:
      return {
        ...state,
        filters: { ...state.filters, accountId: action.payload.accountId },
        pagination: initialState.pagination,
      };
    case actionTypes.SET_USER_TYPE_FILTER:
      return {
        ...state,
        filters: { ...state.filters, type: action.payload.type },
        pagination: initialState.pagination,
      };
    case actionTypes.SET_USER_STATUS_FILTER:
      return {
        ...state,
        filters: { ...state.filters, status: action.payload.status },
        pagination: initialState.pagination,
      };
    case actionTypes.SET_USER_PLAN_FILTER:
      return {
        ...state,
        filters: { ...state.filters, plan: action.payload.plan },
        pagination: initialState.pagination,
      };
    case actionTypes.SET_USER_ACCESS_TEAM_FILTER:
      return {
        ...state,
        filters: { ...state.filters, accessTeam: action.payload.accessTeam },
        pagination: initialState.pagination,
      };
    case actionTypes.CLEAR_USER_FILTERS:
      return { ...state, filters: {}, teams: [] };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default users;
