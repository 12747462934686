export const AUTH_SET_LOADING = 'AUTH/SET_LOADING';
export const AUTH_SET_TOKEN = 'AUTH/SET_TOKEN';
export const AUTH_SIGN_OUT = 'AUTH/SIGN_OUT';
export const AUTH_SET_ERROR = 'AUTH/SET_ERROR';

export const SET_USER_TEAMS = 'USER/SET_USER_TEAMS';
export const SET_USER_SEARCH = 'USER/SET_USER_SEARCH';
export const SET_USER_SORT_BY = 'USER/SET_USER_SORT_BY';
export const SET_USER_PAGINATION = 'USER/SET_USER_PAGINATION';
export const SET_USER_ACCOUNT_ID_FILTER = 'USER/SET_USER_ACCOUNT_ID_FILTER';
export const SET_USER_TYPE_FILTER = 'USER/SET_USER_TYPE_FILTER';
export const SET_USER_STATUS_FILTER = 'USER/SET_USER_STATUS_FILTER';
export const SET_USER_PLAN_FILTER = 'USER/SET_USER_PLAN_FILTER';
export const SET_USER_ACCESS_TEAM_FILTER = 'USER/SET_USER_ACCESS_TEAM_FILTER';
export const CLEAR_USER_FILTERS = 'USER/CLEAR_USER_FILTERS';

export const SET_TEAM_SEARCH = 'TEAM/SET_TEAM_SEARCH';
export const SET_TEAM_SORT_BY = 'TEAM/SET_TEAM_SORT_BY';
export const SET_TEAM_PAGINATION = 'TEAM/SET_TEAM_PAGINATION';

export const SET_TEAM_SET_SEARCH = 'TEAM/SET_TEAM_SET_SEARCH';
export const SET_TEAM_SET_SORT_BY = 'TEAM/SET_TEAM_SET_SORT_BY';
export const SET_TEAM_SET_PAGINATION = 'TEAM/SET_TEAM_SET_PAGINATION';

export const SET_FACILITY_SEARCH = 'FACILITY/SET_FACILITY_SEARCH';
export const SET_FACILITY_SORT_BY = 'FACILITY/SET_FACILITY_SORT_BY';
export const SET_FACILITY_USER_ID = 'FACILITY/SET_FACILITY_USER_ID';
export const SET_FACILITY_CONTACT_ID = 'FACILITY/SET_FACILITY_CONTACT_ID';
export const CLEAR_FACILITY_FILTERS = 'FACILITY/CLEAR_FACILITY_FILTERS';
export const DELETE_FACILITY_IN_PROGRESS = 'FACILITY/DELETE_FACILITY_IN_PROGRESS';
export const DELETE_FACILITY_SUCCEED = 'FACILITY/DELETE_FACILITY_SUCCEED';
export const DELETE_FACILITY_ERROR = 'FACILITY/DELETE_FACILITY_ERROR';

export const SET_LOG_PAGE = 'LOG/SET_LOG_PAGE';
export const SET_LOG_SORT_BY = 'LOG/SET_LOG_SORT_BY';
export const SET_LOG_TYPE_FILTER = 'LOG/SET_LOG_TYPE_FILTER';
export const SET_LOG_TYPE_OPTIONS = 'LOG/SET_LOG_TYPE_OPTIONS';
export const SET_LOG_FACILITY_ID = 'LOG/SET_LOG_FACILITY_ID';
export const SET_LOG_ACCOUNT_ID = 'LOG/SET_LOG_ACCOUNT_ID';
export const SET_LOG_DEVICE_ID = 'LOG/SET_LOG_DEVICE_ID';
export const SET_LOG_AREA_ID = 'LOG/SET_LOG_AREA_ID';
export const SET_LOG_FROM_COMPLETED_DATE = 'LOG/SET_LOG_FROM_COMPLETED_DATE';
export const SET_LOG_TO_COMPLETED_DATE = 'LOG/SET_LOG_TO_COMPLETED_DATE';
export const CLEAR_LOG_FILTERS = 'LOG/CLEAR_LOG_FILTERS';

export const SET_LOG_TYPE_PAGE = 'LOGTYPE/SET_LOG_TYPE_PAGE';
export const SET_LOG_TYPE_SORT_BY = 'LOGTYPE/SET_LOG_TYPE_SORT_BY';
export const SET_LOG_TYPE_SEARCH = 'LOGTYPE/SET_LOG_TYPE_SEARCH';
export const SET_LOG_TYPE_ACCOUNT_ID = 'LOGTYPE/SET_LOG_TYPE_ACCOUNT_ID';
export const SET_LOG_TYPE_ENABLED_FILTER = 'LOGTYPE/SET_LOG_TYPE_ENABLED_FILTER';
export const SET_LOG_TYPE_READONLY_FILTER = 'LOGTYPE/SET_LOG_TYPE_READONLY_FILTER';
export const CLEAR_LOG_TYPE_FILTERS = 'LOGTYPE/CLEAR_LOG_TYPE_FILTERS';

export const SET_FLOW_SORT_BY = 'FLOW/SET_FLOW_SORT_BY';
export const SET_FLOW_SEARCH = 'FLOW/SET_FLOW_SEARCH';
export const SET_FLOW_PAGINATION = 'FLOW/SET_FLOW_PAGINATION';

export const SET_FLOW_MAKE = 'FLOW/SET_FLOW_MAKE';
export const SET_FLOW_MODEL = 'FLOW/SET_FLOW_MODEL';
export const SET_FLOW_CATEGORY = 'FLOW/SET_FLOW_CATEGORY';
export const SET_FLOW_FACILITY_ID = 'FLOW/SET_FLOW_FACILITY_ID';
export const SET_FLOW_WO_TYPE_ID = 'FLOW/SET_FLOW_WO_TYPE_ID';
export const SET_FLOW_WO_AREA_ID = 'FLOW/SET_FLOW_WO_AREA_ID';
export const SET_FLOW_WO_STATUSES = 'FLOW/SET_FLOW_WO_STATUSES';
export const CLEAR_FLOW_FILTERS = 'FLOW/CLEAR_FLOW_FILTERS';

export const SET_DEVICES_SORT_BY = 'DEVICES/SET_DEVICES_SORT_BY';
export const SET_DEVICES_SEARCH = 'DEVICES/SET_DEVICES_SEARCH';
export const SET_DEVICES_MAKES = 'DEVICES/SET_DEVICES_MAKES';
export const SET_DEVICES_CATEGORIES = 'DEVICES/SET_DEVICES_CATEGORIES';
export const SET_DEVICES_MODELS = 'DEVICES/SET_DEVICES_MODELS';
export const SET_DEVICES_USER_ID = 'DEVICES/SET_DEVICES_USER_ID';
export const SET_DEVICES_FACILITY_ID = 'DEVICES/SET_DEVICES_FACILITY_ID';
export const CLEAR_DEVICE_FILTERS = 'DEVICES/CLEAR_DEVICE_FILTERS';
