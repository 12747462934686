import { combineReducers } from 'redux';

import flow from './flow';
import auth from './auth';
import users from './users';
import teams from './teams';
import teamSets from './teamSets';
import devices from './devices';
import facilities from './facilities';
import logs from './logs';
import logTypes from './logTypes';

const rootReducer = combineReducers({
  auth,
  flow,
  users,
  teams,
  teamSets,
  devices,
  facilities,
  logs,
  logTypes,
});

export default rootReducer;
