import { Redirect, Route } from 'react-router-dom';

import { useCognito } from '@uptime/shared/providers/CognitoProvider';

const PrivateRoute = (props) => {
  const { isAuthorized, isLoadingSession } = useCognito();

  if (isLoadingSession) return null;

  if (!isAuthorized) return <Redirect to="/login" />;

  return <Route {...props} />;
};

export default PrivateRoute;
