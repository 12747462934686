import { createContext, useState } from 'react';
import * as PropTypes from 'prop-types';

const initialState = {
  permissions: [],
  hasPermissionsLoaded: false,
  setPermissions: () => null,
  setHasPermissionsLoaded: () => null,
  cleanupPermissions: () => null,
};

export const PermissionsContext = createContext(initialState);

const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(initialState.permissions);
  const [hasPermissionsLoaded, setHasPermissionsLoaded] = useState(false);

  const cleanupPermissions = () => {
    setPermissions(initialState.permissions);
  };

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        hasPermissionsLoaded,
        setPermissions,
        setHasPermissionsLoaded,
        cleanupPermissions,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
};

export default PermissionsProvider;
