import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import muiTheme from '@uptime/shared/theme/muiTheme';
import ThemedSnackbar from '@uptime/shared/theme/ThemedSnackbar';
import { CognitoProvider } from '@uptime/shared/providers/CognitoProvider';
import { COGNITO_ENV_ADMIN } from '@uptime/shared/constants/cognito';

import { client } from './graphql/apolloClient';
import { persistor, store } from './store';
import App from './App';
import UIProvider from './providers/UIProvider';
import PermissionsProvider from './providers/PermissionsProvider';
import reportWebVitals from './reportWebVitals';
import './index.css';

// @ts-ignore
window.store = store;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <CognitoProvider cognitoEnv={COGNITO_ENV_ADMIN}>
          <ApolloProvider client={client}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              Components={{
                success: ThemedSnackbar,
                error: ThemedSnackbar,
                warning: ThemedSnackbar,
                info: ThemedSnackbar,
              }}
            >
              <ThemeProvider theme={muiTheme}>
                <PermissionsProvider>
                  <UIProvider>
                    <App />
                  </UIProvider>
                </PermissionsProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </CognitoProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
