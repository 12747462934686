import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';

import { COGNITO_KEYS, COGNITO_ENV_ADMIN } from '@uptime/shared/constants/cognito';

import { logout } from '../common/utils';

const AUTH_HEADER = 'p-auth-token';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Context creation failed: jwt expired') {
        logout();
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(COGNITO_KEYS(COGNITO_ENV_ADMIN).ID_TOKEN);

  return {
    headers: {
      ...headers,
      [AUTH_HEADER]: token,
    },
  };
});

const multiApiLink = new MultiAPILink({
  endpoints: {
    file: process.env.REACT_APP_GRAPHQL_FILE,
    user: process.env.REACT_APP_GRAPHQL_USER,
    facility: process.env.REACT_APP_GRAPHQL_FACILITY,
    flow: process.env.REACT_APP_GRAPHQL_FLOW,
    newdevice: process.env.REACT_APP_GRAPHQL_NEW_DEVICE,
    device: process.env.REACT_APP_GRAPHQL_DEVICE,
    gocheckit: process.env.REACT_APP_GRAPHQL_GOCHECKIT,
    marketplace: process.env.REACT_APP_GRAPHQL_MARKETPLACE,
    iot: process.env.REACT_APP_GRAPHQL_IOT,
  },
  createHttpLink,
  httpSuffix: '',
});

export const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([errorLink, authLink.concat(multiApiLink)]),
  cache: new InMemoryCache({ addTypename: false }),
});
