export const TEAMS = {
  ADMIN: 'uptimeAdmin',
  PARTNER_ADMINISTRATOR: 'partnerAdministrator',
  PARTNER_USER: 'partnerUser',
  ADMINISTRATOR: 'administrator', // old "facility top"
  MANAGER: 'manager',
  STAFF: 'staff',
  REPAIRER_ADMINISTRATOR: 'repairerAdministrator', // old "technician top"
  DISPATCHER: 'dispatcher',
  TECHNICIAN_DISPATCHER: 'technicianDispatcher', // old "dispatcher user"
  REPAIRER_STAFF: 'repairerStaff', // old "technician"
};

const PLANS = {
  ALL_IN: 'AllIn',
  PRO: 'Pro',
  STARTER_PLUS: 'StarterPlus',
  STARTER: 'Starter',
  COMLPIANCE_PLUS: 'CompliancePlus',
  SERVICE_PLUS: 'UHS-PRO',
  PROTECT: 'UHS-SER',
};

const FORXPLANS = {
  FORX: 'FORx',
  FORXP2: 'FORxP2',
  FORXB: 'FORxPlus',
  FORXP4: 'FORxP4',
};

const ADMIN_TEAM = {
  UTH_ADM_ADMIN: TEAMS.ADMIN,
};

export const PARTNER_TEAMS = {
  UTH_AIP_PARTNER_ADMINISTRATOR: 'partnerAdministratorAllIn',
  UTH_AIP_PARTNER_USER: 'partnerUserAllIn',
};

const FACILITY_TEAMS = {
  UTH_AIP_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.ALL_IN,
  UTH_P_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.PRO,
  UTH_SP_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.STARTER_PLUS,
  UTH_SUTH_S_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.STARTER,
  UTH_COMPLIANCE_PLUS_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.COMLPIANCE_PLUS,
  UHS_PRO_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.SERVICE_PLUS,
  UHS_SER_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + PLANS.PROTECT,
  UTH_FORX_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + FORXPLANS.FORX,
  UTH_FORXP2_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + FORXPLANS.FORXP2,
  UTH_FORXB_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + FORXPLANS.FORXB,
  UTH_FORXP4_FACILITY_ADMINISTRATOR: TEAMS.ADMINISTRATOR + FORXPLANS.FORXP4,
  // todo: add manager, staff
};

const REPAIRER_TEAMS = {
  REPAIRER_ADMINISTRATOR_ALL_IN: TEAMS.REPAIRER_ADMINISTRATOR + PLANS.ALL_IN,
  DISPATCHER_ALL_IN: TEAMS.DISPATCHER + PLANS.ALL_IN,
  TECHNICIAN_DISPATCHER_ALL_IN: TEAMS.TECHNICIAN_DISPATCHER + PLANS.ALL_IN,
  REPAIRER_STAFF_ALL_IN: TEAMS.REPAIRER_STAFF + PLANS.ALL_IN,
};

export const TOP_LEVEL_TEAMS = [
  FACILITY_TEAMS.UTH_AIP_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_P_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_SP_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_SUTH_S_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_COMPLIANCE_PLUS_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UHS_PRO_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UHS_SER_FACILITY_ADMINISTRATOR,
  REPAIRER_TEAMS.REPAIRER_ADMINISTRATOR_ALL_IN,
  PARTNER_TEAMS.UTH_AIP_PARTNER_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORX_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORXP2_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORXB_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORXP4_FACILITY_ADMINISTRATOR,
];

export const FACILITY_TOP_TEAMS = [
  FACILITY_TEAMS.UTH_AIP_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_P_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_SP_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_SUTH_S_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_COMPLIANCE_PLUS_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UHS_PRO_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UHS_SER_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORX_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORXP2_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORXB_FACILITY_ADMINISTRATOR,
  FACILITY_TEAMS.UTH_FORXP4_FACILITY_ADMINISTRATOR,
];
