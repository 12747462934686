import { PayloadAction } from '@reduxjs/toolkit';
import * as actionTypes from '../../constants/actionTypes';
import { ASCENDING_ORDER } from '../../constants/users';

export interface LogTypeState {
  page: number;
  isLoading: boolean;
  error: string;
  search: string;
  filter: any;
  accountId: string;
  sortBy: any;
}

const initialState: any = {
  page: 1,
  isLoading: false,
  error: undefined,
  search: '',
  filter: null,
  accountId: null,
  sortBy: { column: 'title', order: ASCENDING_ORDER.toLowerCase() },
};

const logTypes = (state = initialState, action: PayloadAction<LogTypeState>) => {
  switch (action.type) {
    case actionTypes.SET_LOG_TYPE_PAGE:
      return { ...state, page: action.payload.page };
    case actionTypes.SET_LOG_TYPE_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_LOG_TYPE_SEARCH:
      return { ...state, search: action.payload.search, page: initialState.page };
    case actionTypes.SET_LOG_TYPE_ACCOUNT_ID:
      return { ...state, accountId: action.payload.accountId, page: initialState.page };
    case actionTypes.SET_LOG_TYPE_ENABLED_FILTER:
      return {
        ...state,
        filter: { ...state.filter, isEnabled: action.payload.filter.isEnabled },
        page: initialState.page,
      };
    case actionTypes.SET_LOG_TYPE_READONLY_FILTER:
      return {
        ...state,
        filter: { ...state.filter, readOnly: action.payload.filter.readOnly },
        page: initialState.page,
      };
    case actionTypes.CLEAR_LOG_TYPE_FILTERS:
      return { ...state, ...initialState, accountId: state.accountId };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default logTypes;
