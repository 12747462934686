import get from 'lodash/get';
import { persistor } from '../../store';

export const responseLens = (data = {}, path = undefined, defaultValue = undefined) => {
  if (!path) return undefined;
  return get(data, path, defaultValue);
};

export const logout = () => {
  persistor.purge();
  localStorage.removeItem('token');
  window.location.reload();
};

export const downloadAsBlob = async (url, name) => {
  try {
    if (!url || !name) {
      throw new Error('Response and name parameters are required');
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(response.statusText || 'Something went wrong...');
    }

    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = downloadUrl;
    a.download = `${name}.bpmn`; // Use the name parameter for the file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); // Remove the element after clicking
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Caught an error:', error.message); // Log the error to the console
    throw new Error(error?.message || 'Something went wrong...');
  }
};
