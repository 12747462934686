import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';

const pool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
});

const setupHostedUI = () =>
  Amplify.configure({
    Auth: {
      region: 'us-east-2',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${window.location.origin}/callback`,
        redirectSignOut: `${window.location.origin}/signout`,
        responseType: 'code',
      },
    },
  });

const ERROR_MESSAGES = {
  CANNOT_FIND_CURRENT_USER: 'Can not find current user',
  CANNOT_FIND_COGNITO_USER: 'Can not find cognito user',
};

export const COGNITO_PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

function getCognitoUser(username: string) {
  return new CognitoUser({
    Username: username,
    Pool: pool,
  });
}

function getCurrentUser() {
  return pool.getCurrentUser();
}

export function federatedSingIn() {
  setupHostedUI();
  return Auth.federatedSignIn();
}

export function getFederatedUserSession() {
  setupHostedUI();
  return Auth.currentAuthenticatedUser();
}

export function getUserSession(): Promise<CognitoUserSession> {
  return new Promise(function (resolve, reject) {
    const user = getCurrentUser();

    if (!user) {
      return reject(new Error(ERROR_MESSAGES.CANNOT_FIND_CURRENT_USER));
    }

    user.getSession(function (err: any, result: CognitoUserSession) {
      if (err) {
        reject(err);
        return;
      }

      resolve(result);
    });
  });
}

export function signOut() {
  const user = getCurrentUser();

  if (!user) return new Error(ERROR_MESSAGES.CANNOT_FIND_CURRENT_USER);

  user.signOut();
}

export function refreshSession(refreshToken: string): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();

    if (!user) return new Error(ERROR_MESSAGES.CANNOT_FIND_CURRENT_USER);

    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

    user.refreshSession(cognitoRefreshToken, function (err, result) {
      if (err) {
        reject(err);
        return;
      }

      resolve(result);
    });
  });
}

export function resetPassword(
  username: string,
  oldPassword: string,
  newPassword: string
): Promise<CognitoUserSession> {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) return new Error(ERROR_MESSAGES.CANNOT_FIND_COGNITO_USER);

    const details = new AuthenticationDetails({
      Username: username,
      Password: oldPassword,
    });

    cognitoUser.authenticateUser(details, {
      onSuccess: function (res: CognitoUserSession) {
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
      newPasswordRequired: function (userAttributes: any) {
        delete userAttributes.email_verified;
        delete userAttributes.email;
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
      },
    });
  });
}

export function changePassword(oldPassword: string, newPassword: string) {
  return new Promise((resolve, reject) => {
    const user = getCurrentUser();

    if (!user) {
      return reject(new Error(ERROR_MESSAGES.CANNOT_FIND_COGNITO_USER));
    }

    user.getSession((sessionError: any) => {
      if (sessionError) {
        console.log(sessionError);
        return reject(sessionError);
      }

      user.changePassword(oldPassword, newPassword, function (changePasswordError, result) {
        if (changePasswordError) {
          console.log(sessionError);
          return reject(changePasswordError);
        }

        resolve(result);
      });
    });
  });
}
