import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useOnMount } from '@uptime/shared/hooks';
import { useCognito } from '@uptime/shared/providers/CognitoProvider';

const FederatedSignOut = () => {
  const { federatedSignOut } = useCognito();

  useOnMount(() => {
    setTimeout(federatedSignOut, 1000);
  });

  return (
    <Box
      width="100%"
      height="90vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography component={Box} my={2} color="white">
        Signing out
      </Typography>
      <CircularProgress color="info" size={50} thickness={1} />
    </Box>
  );
};

export default FederatedSignOut;
