import * as actionTypes from '../../constants/actionTypes';
import { DESCENDING_ORDER } from '../../constants/users';

const initialState = {
  page: 1,
  isLoading: false,
  error: undefined,
  logTypes: [],
  filter: {},
  sortBy: { column: 'completedAt', order: DESCENDING_ORDER.toLowerCase() },
};

const logs = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOG_PAGE:
      return { ...state, page: action.payload.page };
    case actionTypes.SET_LOG_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_LOG_TYPE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, flowIds: action.payload.logType },
      };
    case actionTypes.SET_LOG_TYPE_OPTIONS:
      return { ...state, logTypes: action.payload.options };
    case actionTypes.SET_LOG_FACILITY_ID:
      return {
        ...state,
        filter: { ...state.filter, facilityIds: action.payload.filter.facilityIds },
        page: 1,
      };
    case actionTypes.SET_LOG_ACCOUNT_ID:
      return {
        ...state,
        filter: { ...state.filter, accountId: action.payload.filter.accountId },
        page: 1,
      };
    case actionTypes.SET_LOG_DEVICE_ID:
      return {
        ...state,
        filter: { ...state.filter, deviceIds: action.payload.filter.deviceIds },
        page: 1,
      };
    case actionTypes.SET_LOG_AREA_ID:
      return {
        ...state,
        filter: { ...state.filter, areaIds: action.payload.filter.areaIds },
        page: 1,
      };
    case actionTypes.SET_LOG_FROM_COMPLETED_DATE:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
        page: 1,
      };
    case actionTypes.SET_LOG_TO_COMPLETED_DATE:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
        page: 1,
      };
    case actionTypes.CLEAR_LOG_FILTERS:
      return { ...state, filter: null, page: 1, logTypes: [] };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default logs;
