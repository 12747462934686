import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import Callback from './Callback';
import FederatedSignIn from './FederatedSignIn';
import FederatedSignOut from './FederatedSignOut';
import ActivationForm from './Activation/ActivationForm';
import { Mode } from './CognitoLogin.types';

const FORM_MAPPER = {
  [Mode.Activation]: ActivationForm,
  [Mode.FederatedSignIn]: FederatedSignIn,
  [Mode.FederatedSignOut]: FederatedSignOut,
  [Mode.Callback]: Callback,
};

type Props = {
  mode: Mode.Activation | Mode.FederatedSignIn | Mode.FederatedSignOut | Mode.Callback;
  setToken: ({ token }: { token: string | null }) => {};
  cognitoEnv: string;
  welcomeText: string;
};

const useStyles = makeStyles((theme) => ({
  login: {
    // @ts-ignore
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
  },
}));

const CognitoLogin = ({ mode, setToken, cognitoEnv, welcomeText }: Props) => {
  const Form = FORM_MAPPER[mode];
  const classes = useStyles();

  return (
    <Box height="100vh" width="100vw" display="flex" justifyContent="center" className={classes.login}>
      <Box mt={2}>
        <Form setToken={setToken} cognitoEnv={cognitoEnv} welcomeText={welcomeText} />
      </Box>

      <Box position="absolute" bottom="20px" color="white" style={{ opacity: 0.75 }}>
        Copyright © UptimeHealth, Inc {new Date().getFullYear()}
      </Box>
    </Box>
  );
};

export default CognitoLogin;
