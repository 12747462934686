import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Button from '@mui/material/Button';
import TextLogo from '@uptime/shared/components/TextLogo';

import { UIContext } from 'providers/UIProvider';
import { getUserFeatures } from '../../graphql/queries/user';
import useAuth from '../../common/hooks/useAuth';
import usePermissions from '../../common/hooks/usePermissions';
import Menu from './partials/Menu';
import useStyles from './styles';

const REFRESH_DELAY = 2000;

const Layout = (props) => {
  const classes = useStyles();
  const { children } = props;
  const { onSignOut, userName, userType, isLoggedIn, isActiveSession } = useAuth();
  const { setPermissions } = usePermissions();

  const { sidebarIsOpen, setSidebarIsOpen } = useContext(UIContext);

  useQuery(getUserFeatures, {
    skip: !isLoggedIn,
    onCompleted: (data) => setPermissions(data?.getUserFeatures || []),
  });

  const handleDrawerOpen = () => {
    setSidebarIsOpen(true);
  };
  const handleDrawerClose = () => {
    setSidebarIsOpen(false);
  };

  useEffect(() => {
    if (userType === 'partner') {
      onSignOut();
    }
  }, []);

  useEffect(() => {
    // user has logged out in another tab
    if (isLoggedIn && !isActiveSession) {
      // give a small delay to make sure the Cognito session has been cleared out
      setTimeout(() => window.location.reload(), REFRESH_DELAY);
    }
  }, [isLoggedIn, isActiveSession]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, sidebarIsOpen && isLoggedIn && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {isLoggedIn && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, sidebarIsOpen && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            UptimeHealth Admin
          </Typography>
          {userName && <Typography className={classes.userName}>{userName}</Typography>}
          {isLoggedIn && (
            <Button
              onClick={onSignOut}
              startIcon={<ExitToAppIcon />}
              className={classes.logoutBtn}
              data-testid="logout"
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {isLoggedIn && (
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !sidebarIsOpen && classes.drawerPaperClose),
          }}
          open={sidebarIsOpen}
        >
          <div className={classes.toolbarIcon}>
            <TextLogo />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <Menu />
          </List>
        </Drawer>
      )}
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </div>
    </div>
  );
};

export default Layout;
