import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import { Field, Form } from 'react-final-form';
import { useLocation, useHistory } from 'react-router-dom';

import SchemaValidation from '@uptime/shared/validation/SchemaValidation';
import { useOnMount } from '@uptime/shared/hooks';
import { COGNITO_KEYS } from '@uptime/shared/constants/cognito';
import { useCognito } from '@uptime/shared/providers/CognitoProvider';

import Activation from './Activation.schema';
import useStyles from '../styles';

type Props = {
  setToken: ({ token }: { token: string | null }) => {};
  cognitoEnv: string;
  welcomeText: string;
};

const validation = (values: any) => new SchemaValidation(Activation).validateSchema(values);
const trimValue = (value?: string) => (value === undefined ? '' : value.trim());

const ActivationForm = ({ setToken, cognitoEnv, welcomeText }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { resetPassword, isAuthorized } = useCognito();
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState('');
  const [completedResetPassword, setCompletedResetPassword] = useState(false);

  const { code } = qs.parse(location.search) as { code: string };

  const [visibility, setVisibility] = useState({
    password: false,
    confirm: false,
  });

  const handleVisibility = useCallback(
    (name: string) => () =>
      setVisibility((state) => ({
        ...state,
        // @ts-ignore
        [name]: !state[name],
      })),
    []
  );

  const handleConfirm = useCallback(
    async ({ password, username }: { password: string; username: string }) => {
      setError('');

      try {
        await resetPassword(username.trim(), code.trim(), password.trim());
        setCompletedResetPassword(true);
      } catch (e) {
        // @ts-ignore
        setError(e?.message || 'Oops, something went wrong');
      }
    },
    []
  );

  useOnMount(() => {
    if (!code) {
      setError('Invalid activation url');
    }
  });

  if (isAuthorized) {
    const token = localStorage.getItem(COGNITO_KEYS(cognitoEnv).ID_TOKEN);
    dispatch(setToken({ token }));
    history.push('/');
    return null;
  }

  return (
    <Box bgcolor="white" borderRadius="6px" maxWidth="400px" minWidth="320px">
      <Box component={Grid} container justifyContent="center" p={5}>
        <Box textAlign="center" className={classes.text}>
          <strong>Welcome to {welcomeText}!</strong>
          <br />
          {!completedResetPassword && <strong>Please create your account</strong>}
        </Box>

        {!completedResetPassword && (
          <>
            <Box mt={2} component={Grid} item xs={12} textAlign="left" fontSize="0.9rem">
              <strong>Password requirements:</strong> At least 8 characters, a lowercase letter, an uppercase
              letter, a number.
            </Box>

            {error && (
              <Box mt={2} className={classes.error}>
                {error}
              </Box>
            )}

            <Form
              onSubmit={handleConfirm}
              initialValues={{}}
              validate={validation}
              render={({ handleSubmit, submitting }) => {
                return (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <Field name="username" format={trimValue} formatOnBlur>
                      {({ input, meta }) => (
                        <div>
                          <TextField
                            {...input}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username or Email Address"
                            name="username"
                            autoComplete="email"
                            autoFocus
                            disabled={submitting}
                          />
                          {meta.error && meta.touched && <span className={classes.error}>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="password" format={trimValue} formatOnBlur>
                      {({ input, meta }) => (
                        <div>
                          <TextField
                            {...input}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={visibility.password ? 'text' : 'password'}
                            id="password"
                            disabled={submitting}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleVisibility('password')}
                                    onMouseDown={() => null}
                                    edge="end"
                                  >
                                    {visibility.password ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {meta.error && meta.touched && <span className={classes.error}>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Field name="confirm" format={trimValue} formatOnBlur>
                      {({ input, meta }) => (
                        <div>
                          <TextField
                            {...input}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirm"
                            label="Confirm Password"
                            type={visibility.confirm ? 'text' : 'password'}
                            id="confirm"
                            disabled={submitting}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle confirm password visibility"
                                    onClick={handleVisibility('confirm')}
                                    onMouseDown={() => null}
                                    edge="end"
                                  >
                                    {visibility.confirm ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {meta.error && meta.touched && <span className={classes.error}>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <Button
                      type="submit"
                      fullWidth
                      sx={{ mt: 2 }}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={submitting}
                      startIcon={submitting && <CircularProgress size={22} disableShrink />}
                    >
                      Create My Account
                    </Button>
                  </form>
                );
              }}
            />
          </>
        )}
        {completedResetPassword && (
          <Box component={Grid} container justifyContent="center">
            <Grid container alignItems="center" py={4}>
              <CheckIcon color="success" />
              &nbsp;&nbsp;
              <Typography>Your Account created successfully</Typography>
            </Grid>
            <Button data-testid="goToLoginPagebutton">
              <Button type="submit" variant="contained" fullWidth onClick={() => history.push('/app/login')}>
                <Typography>Sign In</Typography>
              </Button>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ActivationForm;
