export enum IDLE_COGNITO_MESSAGE_VALUE {
  SESSION = 'SESSION',
  LOGOUT = 'LOGOUT',
  HIDE = 'HIDE',
}

export const COGNITO_ENV_ADMIN = 'cognito-admin';
export const COGNITO_ENV_PORTAL = 'cognito-portal';
// export const COGNITO_ENV_CONTROLLER = 'cognito';

export const COGNITO_KEYS = (cognitoEnv: string) => {
  return {
    ACCESS_TOKEN: `${cognitoEnv}-access-token`,
    ID_TOKEN: `${cognitoEnv}-id-token`,
    REFRESH_TOKEN: `${cognitoEnv}-refresh-token`,
    ACCESS_TOKEN_PAYLOAD: `${cognitoEnv}-access-token-payload`,
    ID_TOKEN_PAYLOAD: `${cognitoEnv}-id-token-payload`,
  };
};

export const COGNITO_IDLE_MESSAGE = (cognitoEnv: string) => `${cognitoEnv}-idle-message`;
