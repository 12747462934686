import * as actionTypes from './actionTypes';

export const DEVICE_LIST_HEADERS = [
  { id: 'assetId', isSortable: true, label: 'Asset ID', isVisible: true },
  { id: 'makeTitle', isSortable: true, label: 'Make', isVisible: true },
  { id: 'categoryTitle', isSortable: true, label: 'Category', isVisible: true },
  { id: 'modelTitle', isSortable: true, label: 'Model', isVisible: true },
  { id: 'manufactureYear', isSortable: true, label: 'Manufacture Year', isVisible: true },
  { id: 'riskScore', isSortable: true, label: 'Risk Score', isVisible: true },
  { id: 'strategy', isSortable: true, label: 'Service Strategy', isVisible: true },
  { id: 'businessName', isSortable: true, label: 'Top Level Org', isVisible: true },
  { id: 'facilityTitle', isSortable: true, label: 'Facility', isVisible: true },
  { id: 'areaTitle', isSortable: true, label: 'Area', isVisible: true },
  { id: 'managerName', isSortable: true, label: 'Device Manager', isVisible: true },
  { id: 'woCount', isSortable: true, label: '# of Open WO', isVisible: true },
  { id: 'totalCost', isSortable: true, label: 'Total Job Cost', isVisible: true },
  { id: 'lastDate', isSortable: true, label: 'Last Service', isVisible: true },
  { id: 'status', isSortable: true, label: 'Status', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const FILTER_MAP = {
  [actionTypes.SET_DEVICES_MAKES]: 'makeIds',
  [actionTypes.SET_DEVICES_CATEGORIES]: 'categoryIds',
  [actionTypes.SET_DEVICES_MODELS]: 'modelIds',
};
