import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Mode } from '@uptime/shared/components/Login/CognitoLogin.types';
import CognitoLogin from '@uptime/shared/components/Login/CognitoLogin';
import { COGNITO_ENV_ADMIN } from '@uptime/shared/constants/cognito';
import { setToken } from './store/actions/auth';
import Layout from './pages/Layout';

import PrivateRoute from './common/components/PrivateRoute';
import './App.css';

const Users = lazy(() => import('./pages/Users'));
const UserPage = lazy(() => import('./pages/Users/partials/UserPage'));
const ChangePlanPage = lazy(() => import('./pages/Users/partials/ChangePlan'));
const Facilities = lazy(() => import('./pages/Facilities'));
const FacilitiesPage = lazy(() => import('./pages/Facilities/partials/FacilitiesPage'));
const Devices = lazy(() => import('./pages/Devices'));
const DeviceView = lazy(() => import('./containers/Devices/DeviceViewContainer'));
const DeviceUpdate = lazy(() => import('./containers/Devices/DeviceUpdateContainer'));
const WorkOrders = lazy(() => import('./pages/WorkOrders'));
const WorkOrderDevices = lazy(() => import('./pages/WorkOrders/partials/WorkOrderDevices'));
const DashboardAnalyticsView = lazy(() => import('./pages/Analytics'));
const CreateAdmin = lazy(() => import('./pages/Users/partials/CreateAdmin'));
const CreateTopUser = lazy(() => import('./pages/Users/partials/CreateTopUser'));
const CreateTeam = lazy(() => import('./pages/Users/partials/CreateTeam'));
const CreateTeamSet = lazy(() => import('./pages/Users/partials/TeamSet/CreateTeamSet'));
const EditTeam = lazy(() => import('./pages/Users/partials/EditTeam'));
const EditTeamSet = lazy(() => import('./pages/Users/partials/TeamSet/EditTeamSet'));
const Logs = lazy(() => import('./pages/Logs'));
const LogTypes = lazy(() => import('./pages/LogTypes'));

function App() {
  return (
    <Switch>
      <Route path={`/${Mode.FederatedSignIn}`}>
        <CognitoLogin mode={Mode.FederatedSignIn} />
      </Route>
      <Route path={`/${Mode.FederatedSignOut}`} exact>
        <CognitoLogin mode={Mode.FederatedSignOut} />
      </Route>
      <Route path={`/${Mode.Callback}`} exact>
        <CognitoLogin mode={Mode.Callback} setToken={setToken} cognitoEnv={COGNITO_ENV_ADMIN} />
      </Route>
      <Route path={`/${Mode.Activation}`} exact>
        <CognitoLogin
          mode={Mode.Activation}
          setToken={setToken}
          cognitoEnv={COGNITO_ENV_ADMIN}
          welcomeText="UptimeAdmin"
        />
      </Route>
      <Layout>
        <Suspense
          fallback={
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={16} color="primary" />
            </Box>
          }
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Switch>
              <PrivateRoute path="/users/teams/sets/view/:id" component={EditTeamSet} />
              <PrivateRoute path="/users/teams/view/:id" component={EditTeam} />
              <PrivateRoute path="/users/view/:id" component={UserPage} />
              <PrivateRoute path="/users/plan/:accountId" component={ChangePlanPage} />
              <PrivateRoute path="/users/create-admin" component={CreateAdmin} />
              <PrivateRoute path="/users/create-top-user" component={CreateTopUser} />
              <PrivateRoute path="/users/create-team" component={CreateTeam} />
              <PrivateRoute path="/users/create-team-set" component={CreateTeamSet} />
              <PrivateRoute path="/users" component={Users} />
              <PrivateRoute path="/facilities/view/:facilityId" component={FacilitiesPage} />
              <PrivateRoute path="/facilities" component={Facilities} />
              <PrivateRoute path="/devices" component={Devices} exact />
              <PrivateRoute path="/devices/view/:deviceId" component={DeviceView} />
              <PrivateRoute path="/devices/update/:deviceId" component={DeviceUpdate} />
              <PrivateRoute path="/devices" component={Devices} />
              <PrivateRoute path="/work-orders/areas" component={WorkOrders} />
              <PrivateRoute path="/work-orders" component={WorkOrderDevices} />
              <PrivateRoute path="/logs/logtypes" component={LogTypes} />
              <PrivateRoute path="/logs/completed" component={Logs} />
              <PrivateRoute path="/analytics/:dashboardID" component={DashboardAnalyticsView} />
              <Redirect to="/users" />
            </Switch>
          </LocalizationProvider>
        </Suspense>
      </Layout>
    </Switch>
  );
}

export default App;
