import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useOnMount, useOnUpdate } from '../../hooks';
import { useCognito } from '../../providers/CognitoProvider';
import { COGNITO_KEYS } from '../../constants/cognito';

type Props = { setToken: ({ token }: { token: string | null }) => {}; cognitoEnv: string };

const Callback = ({ setToken, cognitoEnv }: Props) => {
  const { getFederatedUserSession, isAuthorized } = useCognito();
  const dispatch = useDispatch();
  const history = useHistory();

  useOnMount(getFederatedUserSession);

  useOnUpdate(() => {
    if (isAuthorized) {
      const token = localStorage.getItem(COGNITO_KEYS(cognitoEnv).ID_TOKEN);
      dispatch(setToken({ token }));
      localStorage.setItem('isActiveSession', 'true');
      history.push('/');
    }
  }, [isAuthorized]);

  return (
    <Box
      width="100%"
      height="90vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography component={Box} my={2} color="white">
        Signing in
      </Typography>
      <CircularProgress color="info" size={50} thickness={1} />
    </Box>
  );
};

export default Callback;
