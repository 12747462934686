import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  search: '',
  sortBy: [
    { column: 'businessName', order: 'asc' },
    { column: 'name', order: 'asc' },
  ],
  pagination: { page: 1, itemsAmount: 10 },
};

const teamSets = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEAM_SET_SEARCH:
      return { ...state, search: action.payload.search, pagination: initialState.pagination };
    case actionTypes.SET_TEAM_SET_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_TEAM_SET_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default teamSets;
