import { gql } from '@apollo/client';

export const getAllUsersForAdmin = gql`
  query getAllUsersForAdmin(
    $pagination: PaginationInput
    $sortBy: [UserForAdminSortInput!]
    $searchBy: String
    $filter: UserForAdminFilterInput
  ) @api(name: "user") {
    getAllUsersForAdmin(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        accountId
        statusId
        statusTitle
        firstName
        lastName
        username
        businessName
        planTitle
        teamTitle
        typeTitle
        typeAlias
        cognitoId
        teamAlias
      }
      itemsCount
      currentPage
      pageCount
    }
  }
`;

export const getAllUsersForOptions = gql`
  query getAllUsersForAdmin(
    $pagination: PaginationInput
    $sortBy: [UserForAdminSortInput!]
    $searchBy: String
    $filter: UserForAdminFilterInput
  ) @api(name: "user") {
    getAllUsersForAdmin(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        firstName
        lastName
        businessName
      }
      itemsCount
      currentPage
      pageCount
    }
  }
`;

export const getAccountUsersCountByStatus = gql`
  query getAccountUsersCountByStatus($accountId: Int!, $statuses: [Int!]!) @api(name: "user") {
    getAccountUsersCountByStatus(accountId: $accountId, statuses: $statuses)
  }
`;

export const getUserProfile = gql`
  query fetchProfile($userId: Int!) @api(name: "user") {
    userData: getProfile(userId: $userId) {
      firstName
      lastName
      mobile
      accountId
      baseUser {
        email
        typeId
      }
      role
      isInviteEmailSent
      teamId
    }
  }
`;

export const getBusinessProfile = gql`
  query getBusinessProfile($accountId: Int!) @api(name: "user") {
    getBusinessProfile(accountId: $accountId) {
      accountId
      name
      email
      phone
    }
  }
`;

export const getUserStatuses = gql`
  query getUserStatuses @api(name: "user") {
    getUserStatuses {
      id
      title
    }
  }
`;

export const getProfile = gql`
  query getUserProfile($userId: Int!) @api(name: "user") {
    profile: getUserProfile(userId: $userId) {
      firstName
      lastName
      mobile
      email
    }
  }
`;

export const whetherUserExists = gql`
  query whetherUserExists($email: String!, $userId: Int) @api(name: "user") {
    isUserExists: whetherUserExists(email: $email, userId: $userId)
  }
`;

export const adminGetFacilityUsers = gql`
  query adminGetFacilityUsers($userId: Int!, $sortBy: [UsersSortInput!]) @api(name: "user") {
    adminGetFacilityUsers(userId: $userId, sortBy: $sortBy) {
      username
      email
      userId
      image
      firstName
      lastName
      mobile
    }
  }
`;

export const adminAccountGroups = gql`
  query adminAccountGroups($userId: Int!) @api(name: "user") {
    adminAccountGroups(userId: $userId) {
      groupId
      name
    }
  }
`;

export const getAccountPlan = gql`
  query adminAccountPlan($accountId: Int!) @api(name: "user") {
    adminAccountPlan(accountId: $accountId) {
      planId
      alias
    }
  }
`;

export const getStaffTeams = gql`
  query getStaffTeams($accountId: Int!) @api(name: "user") {
    getStaffTeams(accountId: $accountId) {
      id
      title
    }
  }
`;

export const getTeamList = gql`
  query groupList(
    $pagination: PaginationInput
    $sortBy: [GroupSortInput!]
    $searchBy: String
    $filter: GroupFilterInput
  ) @api(name: "user") {
    groupList(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        name
        description
        accountId
        businessProfile {
          name
        }
        members {
          userId
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getTeam = gql`
  query group($id: Int!) @api(name: "user") {
    group(id: $id) {
      id
      name
      description
      accountId
      businessProfile {
        name
      }
      members {
        userId
      }
    }
  }
`;

export const isGroupNameExist = gql`
  query isGroupNameExist($name: String!, $accountId: Int!) @api(name: "user") {
    isGroupNameExist(name: $name, accountId: $accountId)
  }
`;

export const getTeamSetList = gql`
  query groupSetList(
    $pagination: PaginationInput
    $sortBy: [GroupSetSortInput!]
    $searchBy: String
    $filter: GroupSetFilterInput
  ) @api(name: "user") {
    groupSetList(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        name
        accountId
        businessProfile {
          name
        }
        groups {
          groupId
          group {
            name
          }
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getTeamSet = gql`
  query groupSet($id: Int!) @api(name: "user") {
    groupSet(id: $id) {
      id
      name
      accountId
      businessProfile {
        name
      }
      groups {
        groupId
      }
    }
  }
`;

export const isGroupSetNameExist = gql`
  query isGroupSetNameExist($name: String!, $accountId: Int!) @api(name: "user") {
    isGroupSetNameExist(name: $name, accountId: $accountId)
  }
`;

export const getUserFeatures = gql`
  query getUserFeatures @api(name: "user") {
    getUserFeatures
  }
`;

export const getPlansByType = gql`
  query getPlansByType($type: String!) @api(name: "user") {
    getPlansByType(type: $type) {
      id
      alias
      description
      title
    }
  }
`;

export const getUniqueTeams = gql`
  query getUniqueTeams @api(name: "user") {
    getUniqueTeams {
      teamAlias
      displayName
      teamTitle
      typeTitle
      planTitle
    }
  }
`;

export const getFeaturesByTagNames = gql`
  query getFeaturesByTagNames($tagNames: [String]) @api(name: "user") {
    getFeaturesByTagNames(tagNames: $tagNames) {
      id
      title
      alias
      publicName
      parentFeatures {
        id
      }
      childFeatures {
        id
      }
    }
  }
`;

export const getTeamsFeatures = gql`
  query getTeamsFeatures($accountId: Int!) @api(name: "user") {
    getTeamsFeatures(accountId: $accountId) {
      accountId
      id
      title
      alias
      featureIds
    }
  }
`;

export const getStudentMemberships = gql`
  query GetStudentMemberships($getStudentMembershipsId: Int!) @api(name: "user") {
    getStudentMemberships(id: $getStudentMembershipsId) {
      enrolled
      id
      title
    }
  }
`;
