import * as actionTypes from './actionTypes';

export const WO_LIST_HEADERS = [
  { id: 'jobId', isSortable: true, label: 'Job ID', isVisible: true },
  { id: 'publicId', isSortable: true, label: 'WO ID', isVisible: true, style: { minWidth: '100px' } },
  { id: 'areaName', isSortable: false, label: 'Area', isVisible: true },
  { id: 'facilityTitle', isSortable: true, label: 'Facility', isVisible: true },
  { id: 'requestedByName', isSortable: false, label: 'Requestor', isVisible: true },
  { id: 'requestedByEmail', isSortable: false, label: 'Requestor Email', isVisible: true },
  { id: 'technicianUserId', isSortable: false, label: 'Technician', isVisible: true },
  { id: 'statusId', isSortable: true, label: 'Status', isVisible: true },
  { id: 'requestedDate', isSortable: true, label: 'Scheduled Date', isVisible: true },
  { id: 'lastActivity', isSortable: false, label: 'Last Action', isVisible: true },
  { id: 'createdAt', isSortable: false, label: 'Days from Request', isVisible: true },
  {
    id: 'requestedByBusinessName',
    isSortable: true,
    label: 'Organization',
    isVisible: true,
    style: { minWidth: '125px' },
  },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const DEVICE_LIST_HEADERS = [
  { id: 'jobId', isSortable: true, label: 'Job ID', isVisible: true },
  { id: 'publicId', isSortable: true, label: 'WO ID', isVisible: true, style: { minWidth: '100px' } },
  { id: 'deviceAssetId', isSortable: true, label: 'Asset ID', isVisible: true },
  { id: 'makeTitle', isSortable: true, label: 'Make', isVisible: true },
  { id: 'categoryTitle', isSortable: true, label: 'Category', isVisible: true },
  { id: 'modelTitle', isSortable: true, label: 'Model', isVisible: true },
  { id: 'deviceManagerName', isSortable: false, label: 'Device Manager', isVisible: true },
  { id: 'facilityTitle', isSortable: true, label: 'Facility', isVisible: true },
  { id: 'requestedByName', isSortable: false, label: 'Requestor', isVisible: true },
  { id: 'requestedByEmail', isSortable: false, label: 'Requestor Email', isVisible: true },
  { id: 'technicianUserId', isSortable: false, label: 'Technician', isVisible: true },
  { id: 'statusId', isSortable: true, label: 'Status', isVisible: true },
  {
    id: 'requestedDate',
    isSortable: true,
    label: 'Scheduled Date',
    isVisible: true,
    style: { minWidth: '120px' },
  },
  { id: 'lastActivity', isSortable: false, label: 'Last Action', isVisible: true },
  { id: 'createdAt', isSortable: false, label: 'Days from Request', isVisible: true },
  {
    id: 'requestedByBusinessName',
    isSortable: true,
    label: 'Organization',
    isVisible: true,
    style: { minWidth: '125px' },
  },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const FILTER_MAP = {
  [actionTypes.SET_FLOW_MAKE]: 'makeId',
  [actionTypes.SET_FLOW_CATEGORY]: 'categoryId',
  [actionTypes.SET_FLOW_MODEL]: 'modelId',
};

export const OPEN_WO_STATUSES = [
  {
    value: 1,
    label: 'Open',
  },
  {
    value: 4,
    label: 'Service Requested',
  },
  {
    value: 5,
    label: 'Scheduled',
  },
  {
    value: 6,
    label: 'In Progress',
  },
  {
    value: 7,
    label: 'On Review',
  },
  {
    value: 8,
    label: 'Accepted',
  },
  {
    value: 9,
    label: 'Assigned',
  },
  {
    value: 10,
    label: 'Rescheduled',
  },
  {
    value: 11,
    label: 'Hold',
  },
  {
    value: 12,
    label: 'Parts on Order',
  },
  {
    value: 13,
    label: 'Needs Review',
  },
  {
    value: 15,
    label: 'Invoiced',
  },
  {
    value: 19,
    label: 'Approval Required',
  },
];

export const WO_STRATEGY = {
  DEVICE: 1,
  AREA: 2,
};
