export const USERS_LIST_HEADER = [
  { id: 'fullName', isSortable: true, label: 'Full Name', isVisible: true },
  { id: 'username', isSortable: true, label: 'Username', isVisible: true },
  { id: 'businessName', isSortable: true, label: 'Top Level Org', isVisible: true },
  { id: 'plan', isSortable: true, label: 'Plan', isVisible: true },
  { id: 'team', isSortable: true, label: 'Access Team', isVisible: true },
  {
    id: 'status',
    isSortable: false,
    label: 'Status',
    isVisible: true,
  },
  {
    id: 'cognitoId',
    isSortable: false,
    label: 'Cognito ID',
    isVisible: true,
  },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const ASCENDING_ORDER = 'ASC';
export const DESCENDING_ORDER = 'DESC';

export const USER_TYPES = {
  ADMIN: 'admin',
  FACILITY: 'facility',
  PARTNER: 'partner',
  TECHNICIAN: 'repairer',
};

export const USER_ROLE_ALIAS = 'User';

export const USER_TYPES_IDS = {
  ADMIN: 1,
  TECHNICIAN: 2,
  FACILITY: 3,
  PARTNER: 4,
};

export const SEND_INVITE_EMAIL_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const EDUCATION_PREMIUM_PLANS_BY_ALIAS = ['UTH-FORX', 'UTH-FORXP2', 'UTH-FORXB', 'UTH-FORXP4'];

export const TYPE_OPTIONS = [
  { value: 'Admin', label: 'Uptime Admin' },
  { value: 'Facility', label: 'Facility' },
  { value: 'Partner', label: 'Partner' },
  { value: 'Repairer', label: 'Technician' },
];

export const USER_STATUSES = {
  Active: 1,
  Syncing: 6,
  Error: 7,
};

export const ADMINISTRATOR_ACCESS_TEAM = 'Administrator';
