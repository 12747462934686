import * as actionTypes from '../../constants/actionTypes';
import { ASCENDING_ORDER } from '../../constants/users';

const initialState = {
  search: '',
  isLoading: false,
  error: undefined,
  filter: {},
  sortBy: { column: 'title', order: ASCENDING_ORDER.toLowerCase() },
};

const facilities = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FACILITY_SEARCH:
      return { ...state, search: action.payload.search };
    case actionTypes.SET_FACILITY_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_FACILITY_USER_ID:
      return { ...state, filter: { ...state.filter, userId: action.payload.filter.userId } };
    case actionTypes.SET_FACILITY_CONTACT_ID:
      return { ...state, filter: { ...state.filter, contactId: action.payload.filter.contactId } };
    case actionTypes.CLEAR_FACILITY_FILTERS:
      return { ...state, filter: null };
    case actionTypes.DELETE_FACILITY_IN_PROGRESS:
      return { ...state, isLoading: action.payload.isLoading };
    case actionTypes.DELETE_FACILITY_ERROR:
      return { ...state, error: action.payload.error };
    case actionTypes.DELETE_FACILITY_SUCCEED:
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default facilities;
