import { ASCENDING_ORDER } from '../../constants/users';
import * as actionTypes from '../../constants/actionTypes';
import { FILTER_MAP } from '../../constants/devices';

const initialState = {
  search: '',
  isLoading: false,
  error: undefined,
  makeIds: undefined,
  categoryIds: undefined,
  modelIds: undefined,
  user: undefined,
  facilityId: undefined,
  sortBy: { column: 'assetId', order: ASCENDING_ORDER.toLowerCase() },
};

const devices = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEVICES_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_DEVICES_SEARCH:
      return { ...state, search: action.payload.search };
    case actionTypes.SET_DEVICES_MAKES:
    case actionTypes.SET_DEVICES_CATEGORIES:
    case actionTypes.SET_DEVICES_MODELS:
      const { dataList = [] } = action.payload;
      const ids = dataList && dataList.map((item) => item.value);
      const keyName = FILTER_MAP[action.type];

      return {
        ...state,
        [keyName]: ids,
      };
    case actionTypes.SET_DEVICES_USER_ID:
      return {
        ...state,
        user: action.payload.user,
      };
    case actionTypes.SET_DEVICES_FACILITY_ID:
      return {
        ...state,
        facilityId: action.payload.facilityId,
      };
    case actionTypes.CLEAR_DEVICE_FILTERS:
      return {
        ...state,
        makeIds: null,
        categoryIds: null,
        modelIds: null,
        user: null,
        facilityId: null,
      };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default devices;
