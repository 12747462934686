import { object } from 'dot-object';

class SchemaValidation {
  constructor(schema) {
    this.schema = schema;
  }

  async validateSchema(values) {
    try {
      await this.schema.validate(values, { abortEarly: false });
    } catch (err) {
      const errors = err?.inner
        ? err.inner.reduce(
            (formError, innerError) => ({
              ...formError,
              [innerError.path]: innerError.message,
            }),
            {}
          )
        : {};

      return object(errors);
    }
  }
}

export default SchemaValidation;
